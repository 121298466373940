
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchBar from "@/components/search-bars/SearchBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Datatable,
    SearchBar,
    Swal,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      trainerList: {},
      active_status: "",
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Photo",
          key: "photo",
          sortable: true,
        },
        {
          name: "Trainer Name",
          key: "trainer_name",
          sortable: true,
        },
        {
          name: "Gender",
          key: "gender",
          sortable: true,
        },
        {
          name: "Phone",
          key: "phone",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
    await this.getTrainerList();
  },
  methods: {
    preventDefault(e) {
      e.preventDefault();
    },
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainerList() {
      await ApiService.get("resource/trainerlist")
        .then((response) => {
          this.trainerList = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    checkActive() {
      this.active_status = "1";
      console.log(this.active_status);
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      if (Object.keys(this.trainerList).length === 0) {
        console.log("No Trainer List");
        Swal.fire({
          title: "Loading Data..",
          text: "Please Wait While the Data is Loading",
          icon: "info",
          showCancelButton: false,
          showConfirmButton: false,
        });
        this.getTrainerList().then(() => {
          Swal.close();
          this.showCourseNotice = true;
        });
      } else {
        this.showCourseNotice = true;
      }
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
